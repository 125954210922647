export var FeatureFlagsKeysEnum;
(function (FeatureFlagsKeysEnum) {
    FeatureFlagsKeysEnum["IS_TEMPLATE_STORE_ENABLED"] = "IS_TEMPLATE_STORE_ENABLED";
    FeatureFlagsKeysEnum["IS_TOPIC_NOTIFICATION_ENABLED"] = "IS_TOPIC_NOTIFICATION_ENABLED";
    FeatureFlagsKeysEnum["IS_MULTI_TENANCY_ENABLED"] = "IS_MULTI_TENANCY_ENABLED";
    FeatureFlagsKeysEnum["IS_USE_MERGED_DIGEST_ID_ENABLED"] = "IS_USE_MERGED_DIGEST_ID_ENABLED";
    FeatureFlagsKeysEnum["IS_API_RATE_LIMITING_ENABLED"] = "IS_API_RATE_LIMITING_ENABLED";
    FeatureFlagsKeysEnum["IS_API_IDEMPOTENCY_ENABLED"] = "IS_API_IDEMPOTENCY_ENABLED";
    FeatureFlagsKeysEnum["IS_TRANSLATION_MANAGER_ENABLED"] = "IS_TRANSLATION_MANAGER_ENABLED";
    FeatureFlagsKeysEnum["IS_API_EXECUTION_LOG_QUEUE_ENABLED"] = "IS_API_EXECUTION_LOG_QUEUE_ENABLED";
    FeatureFlagsKeysEnum["IS_BILLING_ENABLED"] = "IS_BILLING_ENABLED";
    FeatureFlagsKeysEnum["IS_ECHO_ENABLED"] = "IS_ECHO_ENABLED";
    FeatureFlagsKeysEnum["IS_IMPROVED_ONBOARDING_ENABLED"] = "IS_IMPROVED_ONBOARDING_ENABLED";
    FeatureFlagsKeysEnum["IS_NEW_MESSAGES_API_RESPONSE_ENABLED"] = "IS_NEW_MESSAGES_API_RESPONSE_ENABLED";
    FeatureFlagsKeysEnum["IS_BILLING_REVERSE_TRIAL_ENABLED"] = "IS_BILLING_REVERSE_TRIAL_ENABLED";
})(FeatureFlagsKeysEnum || (FeatureFlagsKeysEnum = {}));
